import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'courses-view',
  data() {
    return {
      currentStep: 0,
      currentLocale: null,
      forms: {},
      isLoaded: true
    };
  },
  computed: {
    ...mapGetters({
      packages: 'coursesPackages/list',
      packagesLoading: 'coursesPackages/listLoading',
      basicLocales: 'setting/basicLocales'
    }),
  },
  methods: {
    ...mapActions({
      fetchPackagesList: 'coursesPackages/PACKAGES_GET_LIST',
      fetchFormsList: 'coursesForms/COURSES_FORMS_GET_LIST',
      savePackageForm:  'coursesForms/COURSES_FORMS_SAVE_FORM',
      deletePackageForm: 'coursesForms/COURSES_FORMS_DELETE_FORM',
      fetchBasicLocales: 'setting/GET_BASIC_LOCALES',
    }),
    submitForm() {
      const forms = [];

      for (let packageId in this.forms) {
        for (let locale in this.forms[packageId]) {
          const form = {
            ...this.forms[packageId][locale],
            package_id: packageId,
            course_id: this.$route.params.course_id,
            locale: locale
          }
          forms.push(form)
        }
      }

      this.savePackageForm({forms}).then(() => {
        this.$toasted.success(this.$t('success'));

        this.getForms()
      }).catch(error => {
        if (error.response !== undefined) {
          this.$toasted.error(error.response.data.error.message_key);
        }
        throw error;
      })
    },
    getForms() {
      return this.fetchFormsList({ id: this.$route.params.course_id }).then((list) => {
        const forms = {}
        for (let pack of this.packages) {
          forms[pack.id] = {};
          for (let locale of this.basicLocales) {
            forms[pack.id][locale.locale] = {
              title: '',
              description: ''
            };
          }
        }

        for (let form of list) {
          forms[form.packageId][form.locale] = form;
        }
        this.forms = forms;
      })
    }
  },
  async created () {
    await this.fetchBasicLocales();
    await this.fetchPackagesList({ id: this.$route.params.course_id })
    await this.getForms()

    this.isLoaded = true;
  },
}
